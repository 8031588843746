import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormModal } from '@yanzi/react-ui'
import { Link } from 'react-router-dom'
import createPersistedState from 'use-persisted-state'
import { useActionsPipelineActions } from '../../hooks/useActionsPipelineActions'
import { useActionsPipelineList } from '../../hooks/useActionsPipelineList'

const usePersistedBaseUrlState = createPersistedState('actionsBaseUrl')

function ActionsHostSelector({
  baseUrl,
  setBaseUrl,
}: {
  baseUrl: string
  setBaseUrl: (arg: string) => void
}) {
  const [modal, ask] = useFormModal({
    fields: {
      baseUrl: {
        type: 'text',
        label: 'Actions Host',
        placeholder: 'Base URL for actions instance.',
        defaultValue: baseUrl,
      },
    },
  })

  return (
    <Stack>
      {modal}
      <HStack>
        <Box>Actions Host: {baseUrl}</Box>
        <Button
          variant="link"
          ml={3}
          onClick={async () => {
            const { baseUrl } = await ask()
            setBaseUrl(baseUrl)
          }}
        >
          <FontAwesomeIcon aria-label="Change actions host" size="xs" icon={faPen} />
        </Button>
      </HStack>
    </Stack>
  )
}

export function ActionsPipelineList() {
  const [baseUrl, setBaseUrl] = usePersistedBaseUrlState('https://actions.yanzi.cloud')
  const { list: pipelines, loading } = useActionsPipelineList(baseUrl)
  const { pause, start, terminate } = useActionsPipelineActions(baseUrl)

  return (
    <Container py={6} maxW="container.xl">
      <Stack>
        <HStack>
          <h1>Yanzi Actions Pipelines</h1>
          <Spacer />
          <Box>
            <Button
              as={props => <Link to="/connectors/actions/new" {...props} />}
              colorScheme="accent"
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              New
            </Button>
          </Box>
        </HStack>

        <ActionsHostSelector baseUrl={baseUrl} setBaseUrl={setBaseUrl} />
        {loading ? (
          <Center>
            <Spinner />
          </Center>
        ) : null}

        {pipelines.length ? (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Type</Th>
                <Th></Th>
                <Th></Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {pipelines.map(
                ({
                  id,
                  options: { type, sessionId, accessToken, ...rest },
                  context: { state, states },
                }) => (
                  <Tr key={id}>
                    <Td>
                      {
                        // <Link to={`/connectors/actions/${id}`}>{id}</Link>
                      }
                      {id}
                    </Td>
                    <Td>{type}</Td>
                    <Td>
                      <pre>{JSON.stringify(rest, null, 2)}</pre>
                    </Td>
                    <Td>{state}</Td>
                    <Td>
                      <pre>{JSON.stringify(states)}</pre>
                    </Td>
                    <Td p={0}>
                      {state === 'running' && (
                        <Button size="sm" onClick={() => pause({ id })}>
                          Pause
                        </Button>
                      )}
                      {state === 'paused' && (
                        <Button size="sm" onClick={() => start({ id })}>
                          Start
                        </Button>
                      )}
                      <Button size="sm" onClick={() => terminate({ id })}>
                        Terminate
                      </Button>
                    </Td>
                  </Tr>
                ),
              )}
            </Tbody>
          </Table>
        ) : (
          <Box p={4} textAlign="center" color="#666">
            <Text as="h2">No pipelines found</Text>
            <Box as="p" maxWidth={250} mx="auto !important" my={3} display="block">
              Create new pipelines by using the button in the upper right corner.
            </Box>
          </Box>
        )}
      </Stack>
    </Container>
  )
}
