import { useCirrus } from '@yanzi/react-cirrus'
import { useCallback } from 'react'
import { ConfigAPIRequest, Response } from '../util/configAPI'

export function useConfigAPIFetcher() {
  const { host, socket } = useCirrus()
  const cirrusSessionId = socket.sessionId

  const fetcher = useCallback(
    async function <T extends ConfigAPIRequest>(request: T) {
      return await new Promise<Response<T>>((resolve, reject) => {
        const messageId = `${Math.round(Math.random() * 1000000)}`

        const ws = new WebSocket(`wss://${host}/configAPI`)
        const timeout = setTimeout(() => {
          ws.close()
          reject(new Error('Timeout'))
        }, 30000)

        ws.onmessage = ({ data }) => {
          const message = JSON.parse(data)

          if (message.responseCode === 'success') {
            clearTimeout(timeout)
            ws.close()
            resolve(message)
            return
          } else {
            clearTimeout(timeout)
            ws.close()
            reject(
              new Error(
                message.message ?? 'The server responded with something that was not success.',
              ),
            )
            return
          }
        }

        ws.onopen = () => {
          ws.send(
            JSON.stringify({
              ...request,
              cirrusSessionId,
              messageIdentifier: {
                messageId,
                resourceType: 'MessageIdentifier',
              },
            }),
          )
        }
      })
    },
    [host, cirrusSessionId],
  )

  return fetcher
}
