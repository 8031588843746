import { useConfigAPIFetcher } from './useConfigAPIFetcher'
import { useCallback } from 'react'

export function useIoTHubSetNameFetcher() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({ clientId, name }: { clientId: string; name: string }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'setName',
        data: { clientId, name },
      })
    },
    [fetcher],
  )
}
