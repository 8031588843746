import {
  Badge,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Capability, CapabilityBadge } from '@yanzi/react'
import { useCirrus, useCirrusSessionId } from '@yanzi/react-cirrus'
import { useErrorAlert } from '@yanzi/react-ui'
import { format, parse, subDays } from 'date-fns'
import { FormEvent, useCallback, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryConfigProvider, useMutation } from 'react-query'
import createPersistedState from 'use-persisted-state'
import { VariableName } from '../../__types__/globalTypes'
import { LocationSelector } from './LocationSelector'
import { SlotSizeSelector } from './SlotSizeSelector'
import { UnitSelector } from './UnitSelector'
import { VariableNameSelector } from './VariableNameSelector'

const useCsvUrl = createPersistedState('csv-host')

function createDefaultQueryFunction(baseUrl: string) {
  return (key: string) => fetch(`${baseUrl}/${key}`).then(res => res.json())
}

export function CSVExportScheduler() {
  const [csvUrl, setCsvUrl] = useCsvUrl(
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000/v1'
      : 'https://csv.yanzi.cloud/v1',
  )
  const apiUrl = `${csvUrl}/data-sources`
  const sessionId = useCirrusSessionId()
  const { host } = useCirrus()
  const [columns, setColumns] = useState<{ variableName: VariableName; aggregation: string }[]>([])

  const [timeStart, setTimeStart] = useState(format(subDays(Date.now(), 1), "yyyy-MM-dd'T'HH:mm"))
  const [timeEnd, setTimeEnd] = useState(format(Date.now(), "yyyy-MM-dd'T'HH:mm"))
  const [did, setDid] = useState('')
  const [slotSize, setSlotSize] = useState(0)
  const [locationId, setLocationId] = useState('')
  const [modal, popup] = useErrorAlert()

  const [mutateAsync, { isLoading }] = useMutation(
    async () => {
      const timeStartNumber = parse(timeStart, "yyyy-MM-dd'T'HH:mm", Date.now()).getTime() // TODO Investigate reference date
      const timeEndNumber = parse(timeEnd, "yyyy-MM-dd'T'HH:mm", Date.now()).getTime() // TODO Investigate reference date
      if (isNaN(timeStartNumber) || isNaN(timeEndNumber)) {
        throw new Error('Invalid time')
      }

      const cols = columns.map(({ variableName, aggregation }) => ({
        aggregation,
        dataSourceAddress: {
          resourceType: 'DataSourceAddress',
          variableName: {
            resourceType: 'VariableName',
            name: variableName,
          },
          did,
          locationId,
        },
      }))

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({
          columns: cols,
          sessionId,
          host,
          slot: slotSize,
          timeStart: timeStartNumber,
          timeEnd: timeEndNumber,
        }),
      })
      if (!response.ok) {
        let json = null
        try {
          json = await response.json()
        } catch {
          throw new Error(`Failed with status code ${response.status}: ${response.statusText}`)
        }
        const error = new Error()
        error.message = json.message
        error.name = `${response.status}: ${json.error}`
        throw error
      }
      const json = await response.json()
      const path = json?.path
      if (!path) {
        throw new Error('Unexpected response from server.')
      }

      const url = `${csvUrl}${path}`
      return url
    },
    { throwOnError: true },
  )
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null)
  const download = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      try {
        const url = await mutateAsync()
        if (!url) {
          throw new Error('Unexpected error')
        }
        setDownloadUrl(url)
        // saveAs(url, 'export.csv')
      } catch (e: any) {
        if (e?.message?.includes?.('No samples matched the selection')) {
          popup('Cound not find any samples matching the given selection.')
        } else {
          popup(
            'An error occurred, please try again. Contact Yanzi Support if the problem persists.',
          )
        }
      }
    },
    [mutateAsync, popup],
  )

  const ref = useRef<HTMLButtonElement>(null)

  return (
    <ReactQueryConfigProvider config={{ queries: { queryFn: createDefaultQueryFunction(csvUrl) } }}>
      {modal}
      {downloadUrl ? (
        <Modal isOpen onClose={() => setDownloadUrl(null)} initialFocusRef={ref}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>File generated</ModalHeader>
            <ModalCloseButton />
            <ModalBody>File successfully generated.</ModalBody>
            <ModalFooter>
              <Button
                ref={ref}
                as={props => (
                  <a href={downloadUrl} download="export.csv" {...props}>
                    {props.children}
                  </a>
                )}
                leftIcon={<FontAwesomeIcon icon={faDownload} />}
              >
                Download file
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
      <Container maxW="container.xl" py={6}>
        <h1>
          Yanzi CSV Exporter{' '}
          <Badge colorScheme="accent" color="#fff">
            Beta
          </Badge>
          <Badge ml={2} colorScheme="accent" color="#fff">
            Free during preview
          </Badge>
        </h1>
        <form onSubmit={download}>
          <Grid p={5} gap={6} templateColumns="repeat(2, minmax(0,1fr))">
            <Capability has="developer">
              <GridItem colSpan={2}>
                <FormControl id="csv-url" my={0} position="relative">
                  <FormLabel>
                    CSV app url <CapabilityBadge text />
                  </FormLabel>
                  <Input type="text" value={csvUrl} onChange={e => setCsvUrl(e.target.value)} />
                </FormControl>
              </GridItem>
            </Capability>

            <ErrorBoundary
              fallbackRender={({ resetErrorBoundary }) => (
                <Box>
                  An error occurred. Please try again.{' '}
                  <Button onClick={resetErrorBoundary}>Try again</Button>
                </Box>
              )}
            >
              <GridItem
                opacity={isLoading ? 0.3 : 1}
                pointerEvents={isLoading ? 'none' : undefined}
                userSelect={isLoading ? 'none' : undefined}
              >
                <Stack>
                  <h4>Select device</h4>
                  <ErrorBoundary
                    fallbackRender={({ resetErrorBoundary }) => (
                      <Box>
                        An error occurred. Please try again.{' '}
                        <Button onClick={resetErrorBoundary}>Try again</Button>
                      </Box>
                    )}
                  >
                    <LocationSelector setLocationId={setLocationId} locationId={locationId} />
                  </ErrorBoundary>
                  <UnitSelector
                    locationId={locationId}
                    selectedId={did}
                    onSelect={d => {
                      setDid(d)
                      setColumns([])
                    }}
                  />
                </Stack>
              </GridItem>

              <GridItem
                opacity={isLoading ? 0.3 : 1}
                pointerEvents={isLoading ? 'none' : undefined}
                userSelect={isLoading ? 'none' : undefined}
              >
                <Stack>
                  <h4>Format specification</h4>
                  <SlotSizeSelector slotSize={slotSize} setSlotSize={setSlotSize} />
                  <ErrorBoundary fallbackRender={() => <>An error occurred.</>}>
                    <VariableNameSelector
                      slotSize={slotSize}
                      locationId={locationId}
                      did={did}
                      setColumns={setColumns}
                      columns={columns}
                    />
                  </ErrorBoundary>
                  <FormControl>
                    <FormLabel>Start time</FormLabel>
                    <Input
                      type="datetime-local"
                      name="timeStart"
                      onChange={e => setTimeStart(e.target.value)}
                      value={timeStart}
                      required
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>End time</FormLabel>
                    <Input
                      type="datetime-local"
                      name="timeEnd"
                      id="timeEnd"
                      onChange={e => setTimeEnd(e.target.value)}
                      value={timeEnd}
                      required
                    />
                  </FormControl>
                  <Button type="submit" disabled={!did || !locationId} isLoading={isLoading}>
                    Generate CSV
                  </Button>
                </Stack>
              </GridItem>
            </ErrorBoundary>
          </Grid>
        </form>
      </Container>
    </ReactQueryConfigProvider>
  )
}
