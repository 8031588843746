import { Checkbox, FormControl, FormLabel, Stack } from '@chakra-ui/react'
import { VariableName } from '@yanzi/socket'
import { Ref, RefObject } from 'react'

export function VariableNamesFormGroup({
  variableNames,
  setVariableNames,
  leastDestructiveRef,
}: {
  variableNames: VariableName['name'][]
  setVariableNames: (values: VariableName['name'][]) => void
  leastDestructiveRef?: RefObject<any> | Ref<any>
}) {
  return (
    <FormControl>
      <FormLabel ref={leastDestructiveRef}>Variable names</FormLabel>
      <Stack maxHeight={400} overflowY="auto" position="relative">
        {availableVariableNames.map(variableName => (
          <Checkbox
            key={variableName}
            isChecked={variableNames.includes(variableName)}
            onChange={e => {
              const checked = e.target.checked
              const newLocationIdList = [
                ...variableNames.filter(x => x !== variableName),
                ...(checked ? [variableName] : []),
              ]
              setVariableNames(newLocationIdList)
            }}
            borderColor="gray.200"
          >
            {variableName}
          </Checkbox>
        ))}
      </Stack>
    </FormControl>
  )
}

const enabledVariableNames: Record<VariableName['name'], boolean> = {
  temperature: true,
  temperatureC: true,
  temperatureK: true,
  temperatureF: true,
  totalenergy: true,
  totalApparentEnergy: true,
  totalpower: true,
  electricalPower: true,
  totalpowerInst: true,
  dmdTotalPower: true,
  wattsL: true,
  voltageLtoNSum: true,
  dmdVoltageLtoNSum: true,
  voltageL: true,
  currentL: true,
  operatingHours: true,
  lineFrequency: true,
  onOffInput: true,
  onOffOutput: true,
  onOffTransition: true,
  relativeHumidity: true,
  carbonDioxide: true,
  counter: true,
  motion: true,
  present: true,
  distance: true,
  percentage: true,
  windSpeedGust: true,
  windSpeedAvg: true,
  pressureValue: true,
  rainLast24Hour: true,
  rainRate: true,
  rainLastHour: true,
  windDirection: true,
  pressure: true,
  volt: true,
  timeDraining: true,
  timeDuration: true,
  timeEpoch: true,
  slotAverageValue: true,
  slotAggregateValue: true,
  slotNumberValues: true,
  slotMaxValue: true,
  slotMinValue: true,
  slotMaxValueTime: true,
  slotMinValueTime: true,
  heartRate: true,
  heartRateRecievePower: true,
  heartRatePeriod: true,
  pulseBand: true,
  pulseBandId: true,
  pulseBandType: true,
  costSekPerDay: true,
  videoBandwith: true,
  videoFilePresent: true,
  imageSnapshotPresent: true,
  battery: true,
  upsState: true,
  lampIntensity: true,
  statistics: true,
  rawMessagesTx: true,
  rawMessagesRx: true,
  unitState: true,
  assetUtilization: true,
  uplog: true,
  linkUplog: true,
  alarmState: true,
  log: true,
  logSniffer: true,
  fileRemoveLog: true,
  sampleStatistics: true,
  timeBucket: true,
  qstatistics: true,
  cstats: true,
  positionLog: true,
  extendedEnergySample: true,
  illuminance: true,
  soundPressureLevel: true,
  egoneBootRecord: true,
  image: true,
  buildingModel: true,
  elementCount: true,
  acceleration: true,
  angularVelocity: true,
  weight: true,
  force: true,
  refillList: true,
  volatileOrganicCompound: true,
  openClosed: true,
  siteOnlineStatus: true,
  particulateMatter: true,
  undefined: false,
}

const availableVariableNames = Object.entries(enabledVariableNames)
  .filter(([, show]) => show)
  .map(([variableName]) => variableName)
  .sort((a, b) => a.localeCompare(b)) as VariableName['name'][]
