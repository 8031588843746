import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Spacer,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIoTHubConnectorList } from '../../hooks/useIoTHubConnectorList'
import { useIoTHubSetStateFetcher } from '../../hooks/useIoTHubSetStateFetcher'

export function IoTHubConnectorList() {
  const { list: connectors, loading, refetch } = useIoTHubConnectorList()

  const history = useHistory()
  const setState = useIoTHubSetStateFetcher()

  const setStateAndReload = useCallback(
    async (params: { clientId: string; state: 'idle' | 'running' }) => {
      await setState(params)
      refetch()
    },
    [setState, refetch],
  )

  return (
    <Container pt={4} maxW="container.xl">
      <HStack>
        <h1>IoT Hub Connectors</h1>
        <Spacer />
        <Box>
          <Button
            as={props => <Link to="/connectors/iothub/new" {...props} />}
            colorScheme="accent"
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            New
          </Button>
        </Box>
      </HStack>
      {loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}

      {connectors.length === 0 ? (
        <Box p={5} textAlign="center">
          No IoTHub connectors found.
        </Box>
      ) : (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Connector</Th>
              <Th></Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {connectors.map(({ locationIds, clientId, state, name }) => (
              <Tr key={clientId}>
                <Td>
                  <Link to={`/connectors/iothub/${clientId}`}>{name ?? clientId}</Link>
                </Td>
                <Td>Microsoft IoT Hub</Td>
                <Td>
                  {locationIds?.length} location{(locationIds?.length ?? 0) > 1 ? 's' : ''}{' '}
                  {locationIds?.length ? (
                    <>
                      ({locationIds?.slice(0, 2).join(', ')}
                      {locationIds?.length - 2 > 0 ? ` and ${locationIds?.length - 2} more` : ''})
                    </>
                  ) : null}
                </Td>
                <Td>{state}</Td>
                <Td p={0}>
                  <Button size="sm" onClick={() => history.push(`/connectors/iothub/${clientId}`)}>
                    Inspect
                  </Button>{' '}
                  {state === 'running' && (
                    <Button
                      size="sm"
                      onClick={() => setStateAndReload({ clientId, state: 'idle' })}
                    >
                      Stop
                    </Button>
                  )}
                  {state === 'idle' && (
                    <Button
                      size="sm"
                      onClick={() => setStateAndReload({ clientId, state: 'running' })}
                    >
                      Start
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Container>
  )
}
