import { useQuery } from '@apollo/client'
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react'
import { useAccessToken, useCirrus } from '@yanzi/react-cirrus'
import gql from 'graphql-tag'
import { useEffect, useMemo, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import { useActionsPipelineCreate } from '../../hooks/useActionsPipelineCreate'
import { Groups } from './__types__/Groups'
import { LocationsByGroup } from './__types__/LocationsByGroup'

type SelectorProps = {
  selectedId: string
  onSelect(id: string): void
}

const usePersistedBaseUrlState = createPersistedState('actionsBaseUrl')

function GroupSelector({ onSelect, selectedId }: SelectorProps) {
  const { data } = useQuery<Groups>(GROUPS_QUERY, {
    context: {
      linkServer: true,
    },
  })

  useEffect(() => {
    if (selectedId === '' && data?.accounts?.length) {
      onSelect(data?.accounts[0].accountNumber!)
    }
  }, [data, selectedId, onSelect])

  return (
    <FormControl>
      <FormLabel>Group</FormLabel>
      <Select onChange={e => onSelect(e.target.value)} value={selectedId}>
        {data?.accounts.map(({ accountName, accountNumber }) => (
          <option value={accountNumber!} key={accountNumber!}>
            {accountName} ({accountNumber})
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

function LocationSelector({ groupId, onSelect, selectedId }: SelectorProps & { groupId: string }) {
  const { data } = useQuery<LocationsByGroup>(LOCATIONS_QUERY, {
    variables: { groupId },
    context: { linkServer: true },
  })

  const sortedLocations = useMemo(() => {
    if (data?.locations) {
      return data
        .locations!.list!.slice()
        .filter(l => l.accountId === groupId)
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    }
    return []
  }, [data, groupId])

  useEffect(() => {
    if (sortedLocations.length) {
      onSelect(sortedLocations[0].locationId!)
    }
  }, [sortedLocations, onSelect])

  return (
    <FormControl>
      <FormLabel>Location</FormLabel>
      <Select onChange={e => onSelect(e.target.value)} value={selectedId}>
        {sortedLocations.map(location => (
          <option value={location.locationId!} key={location.locationId!}>
            {location.name} ({location.locationId})
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

interface FormProps {
  groupId: string
  locationId: string
  type: 'coffee' | 'andreasIsAtTheOffice' | 'locationOffline'
}

function Form(props: FormProps) {
  const { groupId, locationId, type } = props
  const { host } = useCirrus()
  const { data: accessToken } = useAccessToken(locationId)
  const [baseUrl, setBaseUrl] = usePersistedBaseUrlState('https://actions.yanzi.cloud')
  const [extra, setExtra] = useState('{}')
  const create = useActionsPipelineCreate(baseUrl)

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        create(type, { host, groupId, accessToken, locationId, ...JSON.parse(extra) })
      }}
    >
      <Stack>
        <FormControl>
          <FormLabel>API url</FormLabel>
          <Input type="text" value={baseUrl} onChange={e => setBaseUrl(e.target.value)} />
        </FormControl>

        <FormControl>
          <FormLabel>Group</FormLabel>
          <Input type="text" readOnly value={groupId} required />
        </FormControl>

        <FormControl>
          <FormLabel>Type</FormLabel>
          <Input type="text" readOnly value={type} required />
        </FormControl>

        <input type="hidden" name="host" value={host} />
        <input type="hidden" name="accessToken" value={accessToken} />
        <input type="hidden" name="locationId" value={locationId} />

        <FormControl>
          <FormLabel>Extra JSON</FormLabel>
          <Input type="text" value={extra} onChange={e => setExtra(e.target.value)} />
        </FormControl>

        <Button type="submit" disabled={!groupId} colorScheme="accent" data-test-id="submit-btn">
          Submit
        </Button>
      </Stack>
    </form>
  )
}

export function ActionsNewPipeline() {
  const [groupId, setGroupId] = useState('')
  const [locationId, setLocationId] = useState('')
  const [type, setType] = useState<'coffee' | 'andreasIsAtTheOffice'>('coffee')

  return (
    <Container maxW="container.xl" py={6}>
      <Grid templateColumns="minmax(0, 1fr) minmax(0, 1fr)" gap={6} p={5}>
        <Stack>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Select onChange={e => setType(e.target.value as 'coffee' | 'andreasIsAtTheOffice')}>
              <option value="coffee">Coffee</option>
              <option value="andreasIsAtTheOffice">Andreas Desk</option>
              <option value="locationOffline">Location offline</option>
              <option value="deviceOffline">Device offline</option>
            </Select>
          </FormControl>
          <GroupSelector onSelect={id => setGroupId(id)} selectedId={groupId} />
          <LocationSelector
            groupId={groupId}
            onSelect={id => setLocationId(id)}
            selectedId={locationId}
          />
        </Stack>

        <Stack>
          <h3>Start new action pipeline</h3>
          <Form groupId={groupId} type={type} locationId={locationId} />
        </Stack>
      </Grid>
    </Container>
  )
}

export const GROUPS_QUERY = gql`
  query Groups {
    accounts {
      key
      accountName
      accountNumber
    }
  }
`

export const LOCATIONS_QUERY = gql`
  query LocationsByGroup($groupId: String!) {
    locations(search: $groupId) {
      numPages
      list {
        key
        name
        locationId
        accountId
      }
    }
  }
`
