import { useQuery } from '@apollo/client'
import { Center, Checkbox, FormControl, FormLabel, Spinner, Stack } from '@chakra-ui/react'
import gql from 'graphql-tag'
import { orderBy } from 'lodash'
import { Ref, RefObject } from 'react'

export function LocationsFormGroup({
  locationIds,
  setLocationIds,
  leastDestructiveRef,
}: {
  locationIds: string[]
  setLocationIds: (values: string[]) => void
  leastDestructiveRef?: RefObject<any> | Ref<any>
}) {
  const res = useQuery(GET_ALL_LOCATIONS_QUERY, {
    variables: {},
    context: {
      linkServer: true,
    },
  })
  const locationList = res.data?.locations?.list

  if (!locationList) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const sortedLocations = orderBy(locationList, ['name', 'locationId'])

  return (
    <FormControl>
      <FormLabel ref={leastDestructiveRef}>Locations</FormLabel>
      <Stack maxHeight={400} overflowY="auto" p={2}>
        {sortedLocations.map(({ locationId, name }: Location) => (
          <Checkbox
            onChange={e => {
              const checked = e.target.checked
              const newLocationIdList = [
                ...locationIds.filter(x => x !== locationId!),
                ...(checked ? [locationId!] : []),
              ]
              setLocationIds(newLocationIdList)
            }}
            isChecked={locationIds.includes(locationId!)}
            key={locationId!}
            borderColor="gray.300"
          >
            {name} ({locationId})
          </Checkbox>
        ))}
      </Stack>
    </FormControl>
  )
}

export const GET_ALL_LOCATIONS_QUERY = gql`
  query GetAllLocationsQuery {
    locations(first: 10000) {
      list {
        key
        locationId
        name
      }
    }
  }
`

interface Location {
  locationId: string | null
  name: string | null
}
