import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react'
import { Capability } from '@yanzi/react'
import { useErrorAlert } from '@yanzi/react-ui'
import { FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIoTHubCreateConnector } from '../../hooks/useIoTHubCreateConnector'
import { IoTHubProtocol } from '../../util/configAPI'
import { LocationsFormGroup } from '../LocationsFormGroup'

export function IoTHubNewConnector() {
  const [name, setName] = useState('')
  const [errorAlert, setError] = useErrorAlert()
  const [connectionString, setConnectionString] = useState('')
  const [protocol, setProtocol] = useState<IoTHubProtocol>('AMQPS')
  const [locationIds, setLocationIds] = useState<string[]>([])

  const create = useIoTHubCreateConnector()

  const history = useHistory()

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      await create({ name, connectionString, protocol, locationIds })
      history.push('/connectors')
    } catch (e: any) {
      setError(e.message ?? 'Unknown network error.')
    }
  }

  return (
    <Container maxW="container.xl" p={5} py={6}>
      <form onSubmit={onSubmit}>
        <Stack>
          <h1>New IoT Hub connector</h1>

          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input type="text" value={name} onChange={e => setName(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Connection string</FormLabel>
            <Input
              type="text"
              value={connectionString}
              onChange={e => setConnectionString(e.target.value)}
            />
          </FormControl>
          <FormControl mt={0}>
            <FormLabel>Protocol</FormLabel>
            <Select value={protocol} onChange={e => setProtocol(e.target.value as IoTHubProtocol)}>
              <option value="AMQPS">AMQPS (recommended)</option>
              <Capability has="developer">
                <option value="AMQPS_WS">AMQPS_WS (dev)</option>
                <option value="HTTPS">HTTPS (dev)</option>
                <option value="MQTT">MQTT (dev)</option>
                <option value="MQTT_WS">MQTT_WS (dev)</option>
              </Capability>
            </Select>
          </FormControl>
          <LocationsFormGroup locationIds={locationIds} setLocationIds={setLocationIds} />

          <Box>
            <Button type="submit" colorScheme="accent">
              Create
            </Button>
          </Box>
        </Stack>
      </form>
      {errorAlert}
    </Container>
  )
}
