import { useCirrus } from '@yanzi/react-cirrus'
import { useCallback } from 'react'

export function useActionsPipelineCreate(baseUrl: string) {
  const { sessionId } = useCirrus().socket
  return useCallback(
    (
      type: string,
      options: { host: string; groupId: string; accessToken: string; locationId: string },
    ) => {
      return fetch(`${baseUrl}/pipelines?pipeline=${type}&sessionId=${sessionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      }).then(res => res.json())
    },
    [sessionId, baseUrl],
  )
}
