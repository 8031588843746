import { useCallback } from 'react'
import { useConfigAPIFetcher } from './useConfigAPIFetcher'

export function useIoTHubDeleteFetcher() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({ clientId }: { clientId: string }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'delete',
        data: { clientId },
      })
    },
    [fetcher],
  )
}
