import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Spacer,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
} from '@chakra-ui/react'
import { Capability } from '@yanzi/react'
import { useConfirmationModal, useFormModal } from '@yanzi/react-ui'
import { VariableName } from '@yanzi/socket'
import gql from 'graphql-tag'
import { useHistory, useParams } from 'react-router-dom'
import { useIoTHubAddLocationIdFetcher } from '../../hooks/useIoTHubAddLocationIdFetcher'
import { useIoTHubAddVariableNameFetcher } from '../../hooks/useIoTHubAddVariableNameFetcher'
import { useIoTHubConnector } from '../../hooks/useIoTHubConnector'
import { useIoTHubDeleteFetcher } from '../../hooks/useIoTHubDeleteFetcher'
import { useIoTHubRemoveLocationIdFetcher } from '../../hooks/useIoTHubRemoveLocationIdFetcher'
import { useIoTHubRemoveVariableNameFetcher } from '../../hooks/useIoTHubRemoveVariableNameFetcher'
import { useIoTHubSetAssetsOnlyFetcher } from '../../hooks/useIoTHubSetAssetsOnlyFetcher'
import { useIoTHubSetNameFetcher } from '../../hooks/useIoTHubSetNameFetcher'
import { useIoTHubSetStateFetcher } from '../../hooks/useIoTHubSetStateFetcher'
import { ConfigAPIResponse } from '../../util/configAPI'
import { LocationsFormGroup } from '../LocationsFormGroup'
import { VariableNamesFormGroup } from '../VariableNamesFormGroup'

export function IoTHubConnectorDetail() {
  const { clientId } = useParams<{ clientId: string }>()
  const { connector, refetch } = useIoTHubConnector({ clientId })

  const setState = useIoTHubSetStateFetcher()
  const doDelete = useIoTHubDeleteFetcher()
  const addLocationId = useIoTHubAddLocationIdFetcher()
  const removeLocationId = useIoTHubRemoveLocationIdFetcher()
  const addVariableName = useIoTHubAddVariableNameFetcher()
  const removeVariableName = useIoTHubRemoveVariableNameFetcher()
  const setName = useIoTHubSetNameFetcher()
  const setAssetsOnly = useIoTHubSetAssetsOnlyFetcher()

  const [confirmationModal, confirm] = useConfirmationModal()
  const history = useHistory()

  const [varNamesModal, askVarNames] = useFormModal({
    title: 'Please select the variableNames that should be pushed by the connector',
    fields: {
      varNames: {
        type: 'custom',
        component: (options, ref) => (
          <VariableNamesFormGroup
            leastDestructiveRef={ref}
            variableNames={options.value || []}
            setVariableNames={options.setValue}
          />
        ),
        defaultValue: connector?.variableNames ?? [],
      },
    },
  })

  const [editLocationsModal, askEditLocations] = useFormModal({
    title: 'Please select which locations this connector should push data from',
    fields: {
      lids: {
        type: 'custom',
        component: (options, ref) => (
          <LocationsFormGroup
            leastDestructiveRef={ref}
            locationIds={options.value || []}
            setLocationIds={options.setValue}
          />
        ),
        defaultValue: connector?.locationIds ?? [],
      },
    },
  })

  const [askNameModal, askName] = useFormModal({
    title: 'Please enter the new name of the connector below',
    fields: {
      name: {
        type: 'text',
        label: 'New name',
        defaultValue: connector?.name ?? '',
      },
    },
  })

  if (!connector) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const start = async () => {
    await setState({
      clientId,
      state: 'running',
    })
    await refetch()
  }

  const stop = async () => {
    await setState({
      clientId,
      state: 'idle',
    })
    await refetch()
  }

  const remove = async () => {
    await confirm('Are you sure that you want to delete this connector?')
    await doDelete({ clientId })
    await refetch()
    history.push('/connectors')
  }

  const editLocations = async () => {
    const { lids } = await askEditLocations()

    const newLocationIds = lids as string[]

    const added = newLocationIds.filter(x => !connector.locationIds?.includes(x))
    const removed = connector.locationIds?.filter(x => !newLocationIds.includes(x)) ?? []

    const promises: Promise<ConfigAPIResponse>[] = [
      ...added.map(locationId => addLocationId({ locationId, clientId })),
      ...removed.map(locationId => removeLocationId({ locationId, clientId })),
    ]

    await Promise.all(promises)
    await refetch()
  }

  const rename = async () => {
    const { name } = await askName()
    await setName({ name, clientId })
    await refetch()
  }

  const editVariableNames = async () => {
    const { varNames } = await askVarNames()

    const newVariableNames = varNames as VariableName['name'][]

    const added = newVariableNames.filter(x => !connector.variableNames?.includes(x))
    const removed = connector.variableNames?.filter(x => !newVariableNames.includes(x)) ?? []

    await Promise.all([
      ...added.map(variableName => addVariableName({ variableName, clientId })),
      ...removed.map(variableName => removeVariableName({ variableName, clientId })),
    ] as Promise<ConfigAPIResponse>[])
    await refetch()
  }

  const toggleAssetDataOnly = async () => {
    await setAssetsOnly({ clientId, assetsOnly: !connector.assetsOnly })
    await refetch()
  }

  return (
    <Container maxW="container.xl" py={6}>
      {varNamesModal}
      {editLocationsModal}
      {askNameModal}
      {confirmationModal}
      <HStack>
        <h1>{connector.name ?? 'IoT Hub Connector'}</h1>
        <Spacer />
        <Box>
          <Button title="Rename connector" onClick={rename}>
            Rename
          </Button>
        </Box>
        <Box>
          <Button onClick={() => remove()} colorScheme="red">
            Delete
          </Button>{' '}
          {connector.state === 'idle' && <Button onClick={() => start()}>Start</Button>}
          {connector.state === 'running' && <Button onClick={() => stop()}>Stop</Button>}
        </Box>
      </HStack>

      <Table size="sm">
        <Tbody>
          <Tr>
            <Th>Name:</Th>
            <Td>{connector.name ?? <Text fontStyle="italic">Untitled</Text>}</Td>
          </Tr>
          <Tr>
            <Th>Status:</Th>
            <Td>{connector.state}</Td>
          </Tr>
          <Tr>
            <Th>Client ID:</Th>
            <Td>{connector.clientId}</Td>
          </Tr>
          <Tr>
            <Th whiteSpace="nowrap">Connection string:</Th>
            <Td>{connector.connectionString}</Td>
          </Tr>
          <Capability has="developer">
            <Tr>
              <Th>Push asset data only:</Th>
              <Td>
                <Text mr={2}>
                  {connector.assetsOnly
                    ? 'Yes - push only data generated by assets'
                    : 'No - push all data'}
                </Text>
                <Button onClick={toggleAssetDataOnly}>Toggle</Button>
              </Td>
            </Tr>
          </Capability>
        </Tbody>
      </Table>

      <Box>
        <HStack>
          <h2>Locations</h2>
          <Spacer />
          <Button onClick={editLocations}>Edit locations</Button>
        </HStack>

        <Box>The locations which will push data to the IoT hub.</Box>
        <LocationsTable locationIds={connector.locationIds ?? []} />
      </Box>
      <Capability has="developer">
        <Box>
          <HStack>
            <h2>Variable names</h2>
            <Spacer />
            <Button onClick={editVariableNames}>Edit variable names</Button>
          </HStack>

          <Box>
            When an IoT Hub connector has variable names configured, only the SubscribeData with
            that variable names in the DataSourceAddress will be published.
          </Box>
          <VariableNamesTable variableNames={connector.variableNames ?? []} />
        </Box>
      </Capability>
    </Container>
  )
}

function VariableNamesTable({ variableNames }: { variableNames: VariableName['name'][] }) {
  if (!variableNames.length) {
    return (
      <Box p={3}>
        Since no variable names are configured, all data will be pushed to the IoT Hub.
      </Box>
    )
  }
  return (
    <>
      <Box p={3}>Only the variableName(s) below will be pushed to the IoT Hub.</Box>
      <Table>
        <Tbody>
          {variableNames.map(variableName => (
            <Tr key={variableName}>
              <Th>{variableName}</Th>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  )
}

function LocationsTable({ locationIds }: { locationIds: string[] }) {
  return (
    <Table>
      <Tbody>
        {locationIds.map(locationId => (
          <Location locationId={locationId} key={locationId} />
        ))}
      </Tbody>
    </Table>
  )
}

function Location({ locationId }: { locationId: string }) {
  const res = useQuery(LOCATION_NAME_QUERY, {
    variables: {
      locationId,
    },
  })

  return (
    <Tr>
      <Th>{locationId}</Th>
      <Td>{res.data?.location?.name}</Td>
    </Tr>
  )
}

export const LOCATION_NAME_QUERY = gql`
  query LocationNameDetailQuery($locationId: String!) {
    location(locationId: $locationId) {
      key
      name
    }
  }
`
