import { Button, Stack } from '@chakra-ui/react'
import { faExternalLinkAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Capability, CapabilityBadge } from '@yanzi/react'
import { useAuthenticatedCirrus, useCirrus } from '@yanzi/react-cirrus'
import { Link } from 'react-router-dom'

export function Navigation() {
  const { host, socket } = useCirrus()
  const { signOut } = useAuthenticatedCirrus()

  return (
    <Stack
      minHeight="100%"
      as="nav"
      position="relative"
      backgroundColor="gray.50"
      color="gray.900"
      role="navigation"
      p={6}
      spacing={5}
    >
      <h3>Yanzi Connect</h3>

      <Stack>
        <h4>Connectors</h4>
        <Stack>
          <Item label="IoT Hub" path="/connectors/iothub" />
          <Item label="Yanzi CSV Exporter" path="/connectors/csv" />
          <Capability has="developer">
            <Item label="Yanzi Actions" path="/connectors/actions" />
          </Capability>
        </Stack>
      </Stack>
      <Stack>
        <h4>Shortcuts</h4>
        <Stack>
          <Item
            label="Yanzi Lifecycle"
            href={`https://live.yanzi.cloud?host=${host}&autoLogin=${
              socket.isAuthenticatedWith === 'jwt' ? 'true' : 'false'
            }`}
          />
        </Stack>
      </Stack>

      <Button
        justifyContent="flex-start"
        leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
        onClick={signOut}
      >
        Sign out
      </Button>
    </Stack>
  )
}

function Item({ label, path, href }: { path?: string; label: string; href?: string }) {
  return (
    <Button
      justifyContent="flex-startq"
      as={props =>
        href ? (
          <a href={href} {...props}>
            {props.children}
          </a>
        ) : (
          <Link to={path} {...props} />
        )
      }
      rightIcon={
        <>
          {href ? <FontAwesomeIcon icon={faExternalLinkAlt} /> : null}
          <CapabilityBadge text />
        </>
      }
    >
      {label}
    </Button>
  )
}
