import { FormControl, FormLabel, Radio, RadioGroup, Select, Spinner, Stack } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useQuery } from 'react-query'

export function SlotSizeSelector({
  slotSize,
  setSlotSize,
}: {
  slotSize: number
  setSlotSize: Dispatch<SetStateAction<number>>
}) {
  const { data: slotSizes, isLoading } = useQuery<{ name: string; value: number }[]>('slot-sizes')
  const nonZeroSlotSizes = useMemo(() => slotSizes?.filter(x => !!x.value), [slotSizes])

  return (
    <>
      <FormControl>
        <FormLabel>Aggregations</FormLabel>
        <RadioGroup
          value={slotSize === 0 ? 'all' : 'time'}
          onChange={e => setSlotSize(e === 'all' ? 0 : nonZeroSlotSizes?.[0]?.value ?? 0)}
        >
          <Stack>
            <Radio borderColor="gray.300" value="all">
              All samples
            </Radio>
            <Radio borderColor="gray.300" value="time">
              Aggregated by time
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      {slotSize === 0 ? null : (
        <FormControl>
          <FormLabel>Slot size</FormLabel>
          {isLoading ? <Spinner /> : null}
          <Select onChange={e => setSlotSize(Number(e.target.value))}>
            {nonZeroSlotSizes?.map(({ name, value }) => (
              <option value={value} key={value}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}
