import { gql, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { useDebounce } from '@yanzi/react'
import { useMemo, useState } from 'react'
import { Units, UnitsVariables } from './__types__/Units'

export function UnitSelector({
  onSelect,
  selectedId,
  locationId,
}: {
  locationId: string
  selectedId: string
  onSelect: (id: string) => void
}) {
  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 300)

  const { data, loading } = useQuery<Units, UnitsVariables>(UNITS_QUERY, {
    context: { locationId },
    variables: { locationId, query: debouncedQuery },
    skip: !locationId || debouncedQuery.length < 3,
  })

  const units = useMemo(() => {
    return data?.location?.units.list
  }, [data?.location?.units.list])

  if (!locationId) {
    return null
  }

  return (
    <>
      <FormControl>
        <FormLabel>Unit</FormLabel>
        <Input
          type="text"
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder="Type to search for units..."
        />
        <FormHelperText>Units will appear below when you enter a search term.</FormHelperText>
      </FormControl>
      <Stack>
        {!units && loading ? <Spinner /> : null}
        {units?.map(unit => (
          <Button
            type="button"
            onClick={() => onSelect(unit.unitAddress!.did!)}
            key={unit.unitAddress?.did ?? undefined}
            colorScheme={selectedId === unit.unitAddress?.did ? 'accent' : 'gray'}
            height="auto"
            justifyContent="flex-start"
            textAlign="left"
          >
            <Stack py={3}>
              <HStack>
                <Box>{unit.name}</Box>
                <Tag>
                  <TagLabel>{unit.unitTypeFixed}</TagLabel>
                </Tag>
              </HStack>
              <Box fontSize="xs" fontWeight="normal">
                {unit.unitAddress?.did}
              </Box>
            </Stack>
          </Button>
        ))}
        {units?.length === 0 ? (
          <Box>We could not find a unit matching that search string. Please try another one.</Box>
        ) : null}
      </Stack>
    </>
  )
}

export const UNITS_QUERY = gql`
  query Units($locationId: String!, $query: String!) {
    location(locationId: $locationId) {
      key
      units(search: $query, first: 10) {
        list {
          key
          unitAddress {
            key
            did
          }
          name
          unitTypeFixed
          assetParent {
            key
            name
          }
        }
      }
    }
  }
`
