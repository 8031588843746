import { useConfigAPIFetcher } from './useConfigAPIFetcher'
import { useCallback } from 'react'
import { IoTHubProtocol } from '../util/configAPI'

export function useIoTHubCreateConnector() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({
      protocol,
      connectionString,
      locationIds,
      name,
    }: {
      protocol: IoTHubProtocol
      locationIds: string[]
      connectionString: string
      name: string
    }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'create',
        data: {
          connectionString,
          locationIds,
          protocol,
          name,
        },
      })
    },
    [fetcher],
  )
}
