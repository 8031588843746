import { useCirrus } from '@yanzi/react-cirrus'
import { useEffect, useState } from 'react'

export function useActionsPipelineList(baseUrl: string) {
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState('')
  const {
    host,
    socket: { sessionId },
  } = useCirrus()

  useEffect(() => {
    if (loading || loaded === baseUrl) return
    setLoading(true)
    fetch(`${baseUrl}/pipelines?sessionId=${sessionId}&host=${host}`)
      .then(res => res.json())
      .then(res => {
        setList(res.pipelines)
        setLoaded(baseUrl)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setList([])
        setLoaded(baseUrl)
        setLoading(false)
      })
  }, [loading, loaded, setLoading, setLoaded, setList, sessionId, baseUrl, host])

  return {
    list,
    loading,
  }
}
