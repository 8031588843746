import { Grid, GridItem } from '@chakra-ui/react'
import { brands } from '@yanzi-brands/all'
import { getBrand } from '@yanzi-brands/utils'
import { ApolloProvider } from '@yanzi/react'
import {
  AuthenticatedCirrusProvider,
  CirrusOAuthProvider,
  CirrusProvider,
  useCirrusHost,
} from '@yanzi/react-cirrus'
import { ErrorNotFound } from '@yanzi/react-error'
import { ThemeProvider } from '@yanzi/react-ui'
import { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ActionsNewPipeline } from './components/ActionsNewPipeline'
import { ActionsPipelineList } from './components/ActionsPipelineList'
import { CSVExportScheduler } from './components/CSVExportScheduler'
import { IoTHubConnectorDetail } from './components/IoTHubConnectorDetail'
import { IoTHubConnectorList } from './components/IoTHubConnectorList'
import { IoTHubNewConnector } from './components/IoTHubNewConnector'
import { Logo } from './components/logo'
import { Navigation } from './components/Navigation'

function App() {
  const brand = useMemo(() => getBrand(brands), [])
  const cirrusHostProps = useCirrusHost({ brand, fallbackHost: 'cirrus5.yanzi.se' })

  return (
    <ThemeProvider theme="lifeCycle">
      <CirrusProvider {...cirrusHostProps}>
        <CirrusOAuthProvider idpHint={null}>
          <AuthenticatedCirrusProvider
            icp={brand?.icp ?? null}
            logo={<Logo height="30px" maxH="30px" />}
            methods={['sso', 'username-password']}
            authenticated={
              <ApolloProvider errorHandler={() => {}}>
                <Grid gap={0} height="100%" templateColumns="20rem minmax(0, 1fr)">
                  <GridItem height="100%" overflowY="auto">
                    <Navigation />
                  </GridItem>
                  <GridItem height="100%" overflowY="auto">
                    <Routes />
                  </GridItem>
                </Grid>
              </ApolloProvider>
            }
          />
        </CirrusOAuthProvider>
      </CirrusProvider>
    </ThemeProvider>
  )
}

function Routes() {
  return (
    <Switch>
      <Route exact path="/connectors/iothub">
        <IoTHubConnectorList />
      </Route>
      <Route exact path="/connectors/iothub/new">
        <IoTHubNewConnector />
      </Route>
      <Route exact path="/connectors/iothub/:clientId">
        <IoTHubConnectorDetail />
      </Route>

      <Route exact path="/connectors/csv">
        <CSVExportScheduler />
      </Route>

      <Route exact path="/connectors/actions">
        <ActionsPipelineList />
      </Route>
      <Route exact path="/connectors/actions/new">
        <ActionsNewPipeline />
      </Route>

      <Redirect exact from="/" to="/connectors/iothub" />
      <Route>
        <ErrorNotFound />
      </Route>
    </Switch>
  )
}

export default App
