import { useCallback } from 'react'
import { useConfigAPIFetcher } from './useConfigAPIFetcher'

export function useIoTHubSetStateFetcher() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({ clientId, state }: { clientId: string; state: 'idle' | 'running' }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'setState',
        data: { clientId, state },
      })
    },
    [fetcher],
  )
}
