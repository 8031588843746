import { useConfigAPIFetcher } from './useConfigAPIFetcher'
import { useCallback } from 'react'

export function useIoTHubRemoveLocationIdFetcher() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({ clientId, locationId }: { clientId: string; locationId: string }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'removeLocationId',
        data: { clientId, locationId },
      })
    },
    [fetcher],
  )
}
