import { useCirrus } from '@yanzi/react-cirrus'
import { useCallback, useEffect, useState } from 'react'
import { IoTHubConnector } from '../util/configAPI'

export function useIoTHubConnectorList() {
  const [connectors, setConnectors] = useState<IoTHubConnector[]>([])
  const [update, setUpdate] = useState(1)
  const { host, socket } = useCirrus()
  const [loading, setLoading] = useState(false)

  const cirrusSessionId = socket.sessionId

  useEffect(() => {
    if (update < 0) {
      // Dummy to mark update as used
    }

    setLoading(true)
    const ws = new WebSocket(`wss://${host}/configAPI`)

    ws.onmessage = ({ data }) => {
      const message = JSON.parse(data)
      if (!message.list) {
        console.error('Expected a getList response, but got', message)
        throw new Error('Got wrong response from socket.')
      }
      setConnectors(message.list)
      setLoading(false)
      if (ws.readyState !== ws.CLOSED) {
        ws.close()
      }
    }

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          plugin: 'iothub',
          method: 'getList',
          cirrusSessionId,
        }),
      )
    }

    return () => {
      if (ws.readyState !== ws.CLOSED) {
        ws.close()
      }
    }
  }, [host, cirrusSessionId, setConnectors, update, setLoading])

  const refetch = useCallback(() => {
    setUpdate(old => old + 1)
    return Promise.resolve()
  }, [setUpdate])

  return { list: connectors, refetch, loading }
}
