import { useCirrus } from '@yanzi/react-cirrus'

export function useActionsPipelineActions(baseUrl: string) {
  const { sessionId } = useCirrus().socket
  return {
    start: ({ id }: { id: string }) => {
      fetch(`${baseUrl}/pipelines/${id}?sessionId=${sessionId}&state=running`, {
        method: 'PUT',
      })
        .then(res => res.json())
        .then(res => console.info(res))
    },
    pause: ({ id }: { id: string }) => {
      fetch(`${baseUrl}/pipelines/${id}?sessionId=${sessionId}&state=paused`, {
        method: 'PUT',
      })
        .then(res => res.json())
        .then(res => console.info(res))
    },
    terminate: ({ id }: { id: string }) => {
      fetch(`${baseUrl}/pipelines/${id}?sessionId=${sessionId}`, {
        method: 'DELETE',
      })
        .then(res => res.json())
        .then(res => console.info(res))
    },
  }
}
