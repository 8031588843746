import { useConfigAPIFetcher } from './useConfigAPIFetcher'
import { useCallback } from 'react'
import { VariableName } from '@yanzi/socket'

export function useIoTHubAddVariableNameFetcher() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({ clientId, variableName }: { clientId: string; variableName: VariableName['name'] }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'addVariableName',
        data: { clientId, variableName },
      })
    },
    [fetcher],
  )
}
