import { useConfigAPIFetcher } from './useConfigAPIFetcher'
import { useCallback } from 'react'

export function useIoTHubSetAssetsOnlyFetcher() {
  const fetcher = useConfigAPIFetcher()

  return useCallback(
    ({ assetsOnly, clientId }: { clientId: string; assetsOnly: boolean }) => {
      return fetcher({
        plugin: 'iothub',
        method: 'setAssetsOnly',
        data: { assetsOnly, clientId },
      })
    },
    [fetcher],
  )
}
